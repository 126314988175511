import isBot from './isBot'
import isSuspectedBot from './isSuspectedBot'
import isIFrame from './isIFrame'

//	eslint-disable-next-line
;(function() {
	if (window.__browser_deprecation__) {
		return
	}
	const { site, rollout, fleetConfig, requestUrl } = window.fedops.data
	function sendBeacon(url: string) {
		if (requestUrl.includes('suppressbi=true')) {
			return
		}
		let sent = false
		try {
			sent = navigator.sendBeacon(url)
		} catch (e) {}
		if (!sent) {
			new Image().src = url
		}
	}
	let is_cached = false
	function isCached(part: any) {
		return !!part && part.indexOf('hit') === 0
	}
	const uuidv4 = () =>
		'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
			const r = (Math.random() * 16) | 0,
				v = c === 'x' ? r : (r & 0x3) | 0x8
			return v.toString(16)
		})
	const match = document.cookie.match(
		/ssr-caching="?cache[,#]\s*desc=(\w+)(?:[,#]\s*varnish=(\w+))?(?:[,#]\s*dc[,#]\s*desc=(\w+))?(?:"|;|$)/
	)
	const ish = isBot(window) || isIFrame() || isSuspectedBot() ? 1 : 0
	let caching = 'none'
	if (match && match.length) {
		caching = `${match[1]},${match[2] || 'none'}`
		is_cached = isCached(caching)
	}
	if (!match && window.PerformanceServerTiming) {
		const serverTiming = performance.getEntriesByType('navigation')[0].serverTiming
		if (serverTiming && serverTiming.length) {
			const names = ['cache', 'constnish', 'dc']
			const parts: Array<string> = []
			serverTiming.forEach(function (entry) {
				const i = names.indexOf(entry.name)
				if (i > 0) {
					parts[i] = entry.description
				}
			})
			is_cached = isCached(parts[1]) || isCached(parts[2])
		}
	}
	const types = {
		WixSite: 1,
		UGC: 2,
		Template: 3,
	}
	const st = types[site.siteType] || 0
	const fedOpsAppName = site.isResponsive ? 'thunderbolt-responsive' : 'thunderbolt'
	const { isDACRollout, siteAssetsVersionsRollout } = rollout
	const is_dac_rollout = isDACRollout ? 1 : 0
	const is_sav_rollout = siteAssetsVersionsRollout ? 1 : 0
	const is_rollout = fleetConfig.code === 0 || fleetConfig.code === 1 ? fleetConfig.code : null
	const ts = Date.now() - window.initialTimestamps.initialTimestamp
	const tsn = Date.now() - window.initialTimestamps.initialRequestTimestamp
	let pageVisibilty = 'visible'
	window.fedops.apps = window.fedops.apps || {}
	window.fedops.apps[fedOpsAppName] = { startLoadTime: tsn }
	window.fedops.sessionId = site.sessionId
	window.fedops.vsi = uuidv4()
	window.fedops.is_cached = is_cached
	window.fedops.phaseMark = (phase: string) => {
		const duration = Date.now() - ts
		const phaseUrl =
			'//frog.wix.com/bolt-performance?src=72&evid=22&appName=' +
			fedOpsAppName +
			'&is_rollout=' +
			is_rollout +
			'&is_sav_rollout=' +
			is_sav_rollout +
			'&is_dac_rollout=' +
			is_dac_rollout +
			'&dc=' +
			site.dc +
			'&is_cached=' +
			is_cached +
			'&msid=' +
			site.metaSiteId +
			'&session_id=' +
			window.fedops.sessionId +
			'&name=' +
			phase +
			'&duration=' +
			duration +
			'&ish=' +
			ish +
			'&vsi=' +
			window.fedops.vsi +
			'&caching=' +
			caching +
			'&pv=' +
			pageVisibilty +
			'&v=' +
			window.thunderboltVersion +
			'&url=' +
			requestUrl +
			'&st=' +
			st
		sendBeacon(phaseUrl)
	}
	window.onoffline = () => {
		window.fedops.phaseMark('offline')
	}
	window.ononline = () => {
		window.fedops.phaseMark('online')
	}
	window.fedops.pagehide = () => {
		pageVisibilty = document.visibilityState
		window.fedops.phaseMark(pageVisibilty)
	}

	addEventListener('pageshow', function (event) {
		if (event.persisted) {
			caching = caching + ',browser_cache'
			window.fedops.is_cached = true
		}
	})

	addEventListener('pagehide', window.fedops.pagehide)
	// @ts-ignore Performance Navigation timeing that is suggested is not implemented yet
	if (performance.navigation.type === 1 || performance.navigation.type === 2) {
		window.fedops.phaseMark('page_reload')
	}
	const url =
		'//frog.wix.com/bolt-performance?src=72&evid=21&appName=' +
		fedOpsAppName +
		'&is_rollout=' +
		is_rollout +
		'&is_sav_rollout=' +
		is_sav_rollout +
		'&is_dac_rollout=' +
		is_dac_rollout +
		'&dc=' +
		site.dc +
		'&is_cached=' +
		is_cached +
		'&msid=' +
		site.metaSiteId +
		'&session_id=' +
		window.fedops.sessionId +
		'&ts=' +
		ts +
		'&tsn=' +
		tsn +
		'&ish=' +
		ish +
		'&vsi=' +
		window.fedops.vsi +
		'&caching=' +
		caching +
		'&pv=' +
		pageVisibilty +
		'&v=' +
		window.thunderboltVersion +
		'&url=' +
		requestUrl +
		'&st=' +
		st
	sendBeacon(url)
})()
